.lf-filter-navigation .filter-inner h6 {
  padding-left: 20px;
  padding-right: 20px;
}
#thumbnail-flex-box {
  display: flex;
  flex-wrap: wrap;
}
#thumbnail-flex-box .no-results-message-container {
  width: 100%;
  padding: 50px 0 200px 0;
  text-align: center;
}
@media (max-width: 767.99px) {
  #thumbnail-flex-box .lf-card {
    width: calc((100% - (30px * (1 - 1))) / 1);
    margin-right: 30px;
  }
  #thumbnail-flex-box .lf-card:nth-child(1n + 1) {
    margin-right: 0;
  }
  #thumbnail-flex-box .lf-card .badge-container {
    position: absolute;
    display: block;
    z-index: 100;
    top: 25px;
    left: 25px;
    height: 60px;
    width: 130px;
  }
  #thumbnail-flex-box .lf-card .badge-container img {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  #thumbnail-flex-box .lf-card .badge-container img:last-child {
    margin-right: 0;
  }
}
@media (max-width: 990.99px) and (min-width: 768px) {
  #thumbnail-flex-box .lf-card {
    width: calc((100% - (30px * (2 - 1))) / 2);
    margin-right: 30px;
  }
  #thumbnail-flex-box .lf-card:nth-child(2n + 2) {
    margin-right: 0;
  }
  #thumbnail-flex-box .lf-card .badge-container {
    position: absolute;
    display: block;
    z-index: 100;
    top: 25px;
    left: 25px;
    height: 60px;
    width: 130px;
  }
  #thumbnail-flex-box .lf-card .badge-container img {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  #thumbnail-flex-box .lf-card .badge-container img:last-child {
    margin-right: 0;
  }
}
@media (min-width: 991px) {
  #thumbnail-flex-box .lf-card {
    width: calc((100% - (30px * (4 - 1))) / 4);
    margin-right: 30px;
  }
  #thumbnail-flex-box .lf-card:nth-child(4n + 4) {
    margin-right: 0;
  }
  #thumbnail-flex-box .lf-card .badge-container {
    position: absolute;
    display: block;
    z-index: 100;
    top: 25px;
    left: 25px;
    height: 60px;
    width: 130px;
  }
  #thumbnail-flex-box .lf-card .badge-container img {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  #thumbnail-flex-box .lf-card .badge-container img:last-child {
    margin-right: 0;
  }
}
.loading-message {
  padding-top: 40px;
  padding-bottom: 40px;
}
#share-filtered-view-button {
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
}
