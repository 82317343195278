.lf-label-image:hover {
  cursor: pointer;
}
.label-image-modal .modal-body {
  max-height: 400px;
  overflow-y: scroll;
}
.label-image-modal .modal-body img {
  width: 100%;
}
.ingredient-status-red {
  background-color: rgba(236, 0, 0, 0.1);
}
.ingredient-status-red .chemical-name {
  font-weight: bold;
  color: #ef0000;
}
.ingredient-status-orange .chemical-name {
  font-weight: bold;
  color: #f78e1e;
}
.ingredient-status-eu-chemical-of-concern .chemical-name {
  color: #e3bb5c;
}
.product-website-link {
  color: #ef4900;
}
